import React from 'react'
import cn from 'classnames';
import { useSiteMetadata } from '../components/SiteData'
import AppLayout from "../components/AppLayout";
import PageInfo from '../components/PageInfo'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import AnchorLink from '../components/shared/AnchorLink'
import Spacing from '../reused-libraries/Spacing'

export default function Home() {
	let {productName} = useSiteMetadata();

	const logoVariant = "whiteout";

	return (
		<AppLayout
			logoVariant={logoVariant}
		>
			<PageInfo
				title={productName}
			/>

			<HomeContent/>

		</AppLayout>
	);
}

const useHomeContentStyles = makeStyles(theme => ({
	mainHeadline: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		position: "relative",

		[theme.breakpoints.up('md')]: {
			minHeight: "100%",
			flexGrow: "1",
			marginTop: theme.spacing(2),
		},
	},
	mainHeadlineWrapper: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "start",
		flexGrow: "1",
	},
	mainHeadlineContent: {
		flexGrow: "1",
		display: "flex",
		flexDirection: "column",
		marginTop: theme.spacing(6),
		// [theme.breakpoints.up('md')]: {
		// 	marginTop: "68px",
		// },
		// [theme.breakpoints.up('lg')]: {
		// 	marginTop: "96px",
		// },
		[theme.breakpoints.up('sm')]: {
			padding: [[0, theme.spacing(6)]],
		},
	},
}), {name: "HomeContent"});

function HomeContent() {
	const classes = useHomeContentStyles();

	return (
		<section className={classes.mainHeadline}>
			<div className={classes.mainHeadlineWrapper}>
				<div className={cn(classes.mainHeadlineContent, "container-fluid")}>

					<Typography component="h1" variant="h2" gutterBottom>
						Notice:
					</Typography>

					<Typography gutterBottom>
						LogSpace has been discontinued as of June 7, 2024. As previously communicated, all user accounts
						were deactivated at that time. Access to LogSpace is no longer available.
					</Typography>

					<Spacing py={6}>
						<AnchorLink href="https://moment-software.com" suppressDecoration>Moment Software LLC website</AnchorLink>
					</Spacing>
				</div>
			</div>
		</section>
	);
}
HomeContent.propTypes = {
};
